// Store
import store from "@/store";

export interface AppPartnerCreateIndex {
  organization_id: string | string[];
  place_id: string | string[];
  content: {
    title: string;
    address: string;
    phone: string;
    identificationNumber: string;
    vatNumber: string;
    paidEstimateExit: {
      hrk: string,
      eur: string,
    };
    paidEstimateEntry: {
      hrk: string,
      eur: string,
    };
  }
}

export class AppPartnerCreateIndex implements AppPartnerCreateIndex {
  organization_id: string | string[];
  place_id: string | string[];
  content: {
    title: string;
    address: string;
    phone: string;
    identificationNumber: string;
    vatNumber: string;
    paidEstimateExit: {
      hrk: string,
      eur: string,
    };
    paidEstimateEntry: {
      hrk: string,
      eur: string,
    };
  }

  constructor() {
    this.organization_id = store.getters.getOrganizationMember.organization_id ?? "";
    this.place_id = "";
    this.content = {
      title: "",
      address: "",
      phone: "",
      identificationNumber: "",
      vatNumber: "",
      paidEstimateExit: {
        hrk: "0",
        eur: "0",
      },
      paidEstimateEntry: {
        hrk: "0",
        eur: "0",
      },
    };
  }
}