
// Vue
import {Options, Vue} from "vue-class-component";

// Models
import {AppPartnerCreateIndex} from "@/models/app/partner/create";
import {ResponseIndex} from "@/models/response";

// Components
import {
  ElDialog,
  ElForm,
  ElFormItem,
  ElInput,
  ElSelect,
  ElOption,
  ElButton,
} from "element-plus";
import {
  Plus,
} from "@element-plus/icons-vue";

// Services
import {postRequest} from "@/services/api/request";
import {getTranslation} from "@/services/app/translation";

@Options({
  props: [
    "places",
  ],
  components: {
    ElDialog,
    ElForm,
    ElFormItem,
    ElInput,
    ElSelect,
    ElOption,
    ElButton,
    Plus,
  },
})
export default class AppAdministratorMasterDataPartnersRootListFormCreateIndexVue extends Vue {
  isLoading = false;
  isDialogVisible = false;

  formData: AppPartnerCreateIndex = new AppPartnerCreateIndex();
  formDataRules = {
    place_id: [
      {
        required: true,
        trigger: "blur",
      },
    ],
    content: {
      title: [
        {
          required: true,
          trigger: "blur",
        },
      ],
      address: [
        {
          required: true,
          trigger: "blur",
        },
      ],
    },
  };

  get translation(): any {
    return getTranslation([
      "add",
      "address",
      "addressPlaceholder",
      "choose",
      "identificationNumber",
      "identificationNumberPlaceholder",
      "phone",
      "phonePlaceholder",
      "place",
      "title",
      "titlePlaceholderPartner",
      "vatNumber",
      "vatNumberPlaceholder",
    ]);
  }

  $refs!: {
    formComponent: HTMLFormElement;
  };

  validateForm(): void {
    this.$refs.formComponent.validate((response: any) => {
      if (response) {
        this.submitForm();
      }
    });
  }

  async submitForm(): Promise<void> {
    this.isLoading = true;
    await postRequest({
      uri: "/common/partner/create",
      formData: this.formData,
      isProtected: true,
      isSuccessNotificationVisible: true,
      isErrorNotificationVisible: true,
    }).then((r: ResponseIndex) => {
      if (r.status === "success") {
        this.$emit('getPartners');
        this.isDialogVisible = false;
        this.resetFormData();
      }
    });
    this.isLoading = false;
  }

  resetFormData(): void {
    this.formData = new AppPartnerCreateIndex();
  }
}
